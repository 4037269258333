import React from 'react';

const IconLoader = () => (
  <svg
    width="522"
    height="522"
    viewBox="0 0 522 522"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="522" height="522" fill="black" />
    <g clipPath="url(#clip0_239_75)">
      <path
        d="M118 155H403L341.342 276.204L305.719 216.32H338.598L355.045 184.942H168.699L185.138 213.498H275.584L324.894 301.87L275.584 393.132L207.066 273.356H245.424L278.311 330.393L293.339 306.54L257.667 244.683L165.955 244.834L118 155Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_239_75">
        <rect width="285" height="238" fill="white" transform="translate(118 155)" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLoader;
