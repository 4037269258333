import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 1000px;
  display: flex;

  .about-text {
    max-width: 600px;
    max-height: 433px;
  }

  .inner {
    grid-gap: 50px;
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;
const StyledPic = styled.div`
  position: relative;
  margin-right: 120px;
  margin-top: 20px;

  @media (max-width: 768px) {
    display: none;
  }

  .mobile-pic {
    position: relative;
    max-width: 300px;

    @media (max-width: 768px) {
      margin: 50px auto 0;
      width: 70%;
    }
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;

      &:after {
        top: 15px;
        right: 15px;
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
        border-radius: var(--border-radius);
      }
    }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--black);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 20px;
      right: 20px;
      z-index: -1;
    }
  }
`;

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = ['React.js', 'Vue.js', '.NET Core', 'SQL', 'JavaScript (ES6+)', 'Java'];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <StyledPic className="mobile-hidden">
        <div className="wrapper">
          <StaticImage
            className="img"
            src="../../images/me.png"
            width={500}
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </div>
      </StyledPic>

      <div className="about-text">
        <h2 className="numbered-heading">
          about<span></span>
        </h2>

        <div className="inner">
          <StyledText>
            <div>
              <p>
                Hey there! My name is Pedro. I'm a design-minded software engineer passionate about
                building beautiful interfaces and writing code for humans and computers.
              </p>

              <p>
                I'm a third year student at{' '}
                <a href="https://en.wikipedia.org/wiki/University_of_S%C3%A3o_Paulo">
                  University of São Paulo
                </a>{' '}
                studying Information Systems. I consider work an ongoing education, and I'm always
                looking for opportunities to work with those who are willing to share their
                knowledge as much as I want to learn.
              </p>

              <p>
                My goal right now is to develop myself in my design and code skills while still
                building something special with people who bring out the best in me.
              </p>

              <p>Here are some technologies I'm working with right now:</p>
            </div>

            <ul className="skills-list">
              {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
            </ul>
          </StyledText>
        </div>
      </div>
    </StyledAboutSection>
  );
};

export default About;
