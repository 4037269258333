import React from 'react';

const IconLogo = () => (
  <svg
    width="285"
    height="238"
    viewBox="0 0 285 238"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_239_76)">
      <path
        d="M0 0H285L223.342 121.204L187.719 61.3199H220.598L237.045 29.9419H50.6986L67.1381 58.4979H157.584L206.894 146.87L157.584 238.132L89.0655 118.356H127.424L160.311 175.393L175.339 151.54L139.667 89.6828L47.9546 89.834L0 0Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_239_76">
        <rect width="285" height="238" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLogo;
